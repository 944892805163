/* CSS Styles */
.pricing-container {
  text-align: center;
  color: #fff;
  background-color: #06071B;
  padding: 2rem;
  padding-bottom: 0%;
  position: absolute; /* Relative to the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Full screen width */
  height: auto; /* Allow content to define height */
  min-height: 100vh; /* Ensure at least full viewport height */
  overflow: hidden; /* Prevent scrolling if unnecessary */
  box-sizing: border-box; /* Includes padding in width and height */
}

  
  .pricing-container h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .pricing-container p {
    margin-bottom: 2rem;
    color: #acacac;
  }

  /* Style for the paragraph and toggle buttons container */
.text-and-toggle {
    display: flex;
    justify-content: space-between; /* Place text on the left and buttons on the right */
    align-items: center; /* Vertically align the text and buttons */
    width: 100%; /* Make sure the container takes full width */
    margin-bottom: 2rem; 
  }
  

  h2 {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
    font-weight: 400; /* Set font weight */
    font-size: 40px; /* Set font size */
    color: white; /* Set text color */
    text-align: left; /* Align text to the left */
    padding-left: 140px; /* Add left padding */
    padding-top: 20px;
  }

  .text-and-toggle p {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
    font-weight: 400; /* Set font weight */
    font-size: 18px; /* Set font size */
    color: #848199; /* Set text color */
    text-align: left; /* Align text to the left */
    padding-left: 140px; /* Add left padding */
    padding-top: 10px;
    flex: 1; 
  }
  
  
  .back-button {
    position: absolute;
    top: 2.5rem;
    left: 1.5rem;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .back-button:hover {
    color: #FF7000;
  }
  
  .toggle-buttons {
    display: flex;
    border-radius: 30px; /* Rounded corners for the group */
    overflow: hidden; /* Ensures buttons stay within the rounded border */
    width: 200px; /* Set width to ensure buttons fit well */
    background-color: #afacac; /* Background color for inactive buttons */
    margin-right: 260px;
  }
  
  .toggle-btn {
flex: 1; /* Ensures buttons are equally sized */
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff; /* Default text color */
  border: none; /* Remove individual button borders */
  background: transparent; /* Matches group background */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  }
  
  .toggle-btn.active {
    background-color: #FF7000;
    color: #fff;
    border-color: #FF7000;
    border-radius: 30px; 
  }

  .toggle-btn:not(.active):hover {
    background-color: #afacac; /* Highlight inactive button on hover */
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background: linear-gradient(to bottom, #1b183a, #0c0a29);
    border-radius: 15px;
    padding: 1.5rem;
    width: 300px;
    text-align: left;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
    transition: transform 0.3s, border 0.3s;
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
  position: relative; 
  }
  
  .pricing-card h3 {
    font-size: 1.5rem;
    margin-top: -15px;
  }


  /* Add border for the most popular pricing card */
.pricing-card.most-popular {
   
    border-radius: 15px; /* Keep the rounded corners */
    padding: 1.5rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Optional, keeps the shadow effect */
    position: relative; /* To position the badge correctly */
  }

  .pricing-card.selected {
    
    padding: calc(1.5rem - 3px); /* Subtract border width from padding to maintain size */
    transform: scale(1.05); 
  }

  /* Optional hover effect */
.pricing-card:hover {
  transform: scale(1.03); /* Slight zoom on hover for a dynamic feel */
  border: 3px solid #FF7000; /* Highlight selected card */
}

  
  .pricing-card h6 {
    color: #848199 !important; /* Ensures it overrides other styles */
  }
  
  
  .pricing-card .savings {
    color: #FF7000;
    font-size: 0.870rem;
    margin-bottom: -19px;
    margin-top: -9.9px;
  }
  
  .pricing-card h4 {
    margin: 1rem 0 0.5rem;
    font-size: 1.25rem;
  }
  
  .pricing-card ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
  }
  
  .pricing-card ul li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    color: #acacac;
  }
 
  
  .pricing-card ul li::before {
    content: "✔";
    color: #FF7000;
    margin-right: 0.5rem;
  }
  
  .choose-btn {
    background-color: #FF7000;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 0.6rem; /* Ensures the button is pushed to the bottom of the card */
    align-self: center; 
  }

  .choose-btn2 {
    background-color: #FF7000;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 5.5rem; /* Ensures the button is pushed to the bottom of the card */
    align-self: center; 
  }

  .choose-btn3 {
    background-color: #FF7000;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 3.5rem; /* Ensures the button is pushed to the bottom of the card */
    align-self: center; 
  }
  
  .choose-btn:hover {
    background-color: #FF7000;
  }
  
  .most-popular {
    position: relative;
  }
  
  .most-popular .badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FF7000;
    color: #fff;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 5px;
  }

  .copyright{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #A0A4A8;
    text-align: center;
    ;
  }
  
  @media (max-width: 480px) {
    .text-and-toggle br {
        display: none; /* Hides the <br> tag on smaller screens */
      }
    .text-and-toggle {
      flex-direction: column; /* Stack text and toggle buttons */
    }
  
  
    .text-and-toggle p {
        font-size: 12px; /* Reduce font size for text */
        text-align: left; /* Align text to the left on smaller screens */
        padding-left: 10px; /* Optional: Add padding for better spacing */
      }


      .toggle-buttons {
        max-width: 200px; /* Reduce button width for smaller screens */
        height: 35px; /* Reduce height */
        align-items: center;
        margin-left: 60px;
      }
  
    .toggle-btn {
      font-size: 10px; /* Smaller font size for mobile */
      line-height: 30px;
    }

    h2 {
        font-family: 'Poppins', sans-serif; /* Apply Poppins font */
        font-weight: 400; /* Set font weight */
        font-size: 40px; /* Set font size */
        color: white; /* Set text color */
        text-align: left; /* Align text to the left */
        padding-left: 35px; /* Add left padding */
        padding-top: 30px;
      }

      .back-button {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background: transparent;
        color: #fff;
        border: none;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
      }

      .pricing-container {
        height: auto; /* Allow content to define height */
        min-height: 100vh; /* Ensure at least full viewport height */
        overflow: hidden; /* Prevent scrolling if unnecessary */
    }
    
    .copyright{
      font-size: 6px;
    }
    
}
  
  @media (max-width: 768px) {
    .text-and-toggle br {
        display: none; /* Hides the <br> tag on smaller screens */
      }
    .pricing-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card {
      width: 90%;
    }

    .text-and-toggle {
        padding: 5px; /* Reduce padding on smaller screens */
        align-items: center;
      }
    
      .text-and-toggle p {
        font-size: 22px; /* Reduce font size for text */
        text-align: left; /* Align text to the left on smaller screens */
        padding-left: 10px; /* Optional: Add padding for better spacing */
      }


      .toggle-buttons {
        max-width: 200px; /* Reduce button width for smaller screens */
        height: 35px; /* Reduce height */
        align-items: center;
        margin-right:60px;
      }
    
      .toggle-btn {
        font-size: 12px; /* Smaller font size for buttons */
        line-height: 35px; /* Adjust line height */
      }
      h2 {
        font-family: 'Poppins', sans-serif; /* Apply Poppins font */
        font-weight: 400; /* Set font weight */
        font-size: 40px; /* Set font size */
        color: white; /* Set text color */
        text-align: left; /* Align text to the left */
        padding-left: 35px; /* Add left padding */
        padding-top: 30px;
      }

      .back-button {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background: transparent;
        color: #fff;
        border: none;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
      }

      .pricing-container {
        height: auto; /* Allow content to define height */
        min-height: 100vh; /* Ensure at least full viewport height */
        overflow: hidden; /* Prevent scrolling if unnecessary */
    }
    

    .copyright{
      font-size: 14px;
    }
  
  }
  